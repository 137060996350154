* {
  box-sizing: border-box;
}

.ant-upload-list-text {
  display: none;
}

.ant-upload-select {
  margin: 0 auto;
  max-height: 128px;
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ant-tabs-nav-operations {
  display: none !important;
}

.intercom-launcher {
  display: none !important;
} // DOM element that is inserted before React
