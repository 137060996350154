.ant-form-item {
  margin-bottom: 0;
}

.ant-modal {
  transform-origin: 50% 50% !important;
}

.ant-space {
  display: flex;
}
