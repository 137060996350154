:local {
  .wrapper {
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .inbox {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .chatBox {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.center-stage {
  margin: 0 !important;
}
