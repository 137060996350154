@font-face {
  font-family: $fontFamily;
  src: url("../assets/fonts/orkney-light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: $fontFamily;
  src: url("../assets/fonts/orkney-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: $fontFamily;
  src: url("../assets/fonts/orkney-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: $fontFamily;
  src: url("../assets/fonts/orkney-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
